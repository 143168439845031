@use '../../styles/app.scss' as *;;

.tag-survey {
  width: 100%;
  position: relative;

  .question-box {
    width: 100%;
    margin-bottom: 12px;
    padding-bottom: 0;
  }

  .tag-box {
    margin-top: 12px;
  }

  .radioOptionBelow {
    display: inline-block;
    text-align: center;
    width: 20%;
    vertical-align: top;
  }

  label {
    font-weight: normal !important;
    display: block;
  }

}
