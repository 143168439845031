@use '../../styles/app.scss' as *;

.alexa-message-template {
  padding-left: 500px;
  padding-top: 200px;

  .title {
    font-size: $ml-font-size-huge;
  }

  .section {
    margin-top: 40px;
  }

  .emphasis {
    font-size: $ml-font-size-h2;
  }

  a {
    color: white;
  }

}
