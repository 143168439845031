@import '../../styles/variables.scss';

.explore-control-bar {

  font-size: $ml-font-size-small;

  button {
    font-size: $ml-font-size-h6;
    border-color: transparent;
  }

  .not-spaced {
    margin-left: 0 !important;
  }

  .control-group {
    display: inline-block;
    margin-left: 12px;

    div {
      display: inline-block;
    }
    button {
      padding: 2px 6px;
      margin-left: 6px;
    }
  }

  .sort-by-dropdown-compact {
    display: none;
  }
  .sort-by-dropdown-default {
    display: inline-block;
  }

  @media (min-width: $ml-screen-sm) {
    .control-group.sort-group {
      float: right;
    }
  }
  @media (max-width: $ml-screen-sm) {
    .control-group {
      display: block;
      margin-left: 0;
    }
    .sort-by-dropdown-compact {
      display: inline-block;
    }

    .sort-by-dropdown-default {
      display: none;
    }
  }
}
