about-your-ratings-page {

  div.about-your-ratings {
    margin-bottom: 48px;
  }

  .ayr-section {
    margin-top: 48px;
  }

}
