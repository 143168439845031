@use '../../styles/app.scss' as *;;

.movie-flag-events {
  tr.strikeout {
    td {
      text-decoration: line-through;
      color: $ml-gray-light;
    }
  }
}
