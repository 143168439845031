@use '../../styles/app.scss' as *;

.ml4-toggle {

  display: inline-block;
  cursor: pointer;
  color: $ml-gray;

  /*
   * hover effect -- wrap in media query to avoid touble-tap issue on IOS
   * see https://css-tricks.com/annoying-mobile-double-tap-link-issue/
   */
  @media (hover) {
    &:hover {
      color: $ml-orange;
    }
  }

  .toggle-on {
    color: $ml-orange;
    background-color: var(--bs-secondary-bg);
    border: 1px solid var(--bs-border-color);
    @include border-radius($ml-border-radius);
    padding: 1px;
  }

  .toggle-off {
    padding: 2px;
  }
}
