@use '../../styles/app.scss' as *;

.base-menu-dropdown {
  width: 650px;
  max-height: 85vh;

  // max-height and overflow to ensure that iphone 4/5 users can scroll the menu
  // see https://github.com/mgcrea/angular-strap/issues/935
  // max-height set in base.js to leverage window height via javascript
  overflow: auto;

  @media (max-width: $ml-screen-xs) {
    max-width: 300px;
  }
  
  ul {
    margin-bottom: 12px !important;
  }
}
