.dev-tmpl {

  .dev-cards-container {

    .movie-card-new {
      margin-right: 10px;
    }

  }

}
