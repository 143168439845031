@use '../../styles/app.scss' as *;

$tv-tmpl-nav-height: 80px;
$tv-tmpl-side-gutter: 20px;

.tv-template {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: black;
  color: white;
  font-size: $ml-font-size-large;

  // top and bottom nav (e.g., "show more >")
  .tv-nav {
    width: 100%;
    padding: 0 $tv-tmpl-side-gutter;
    height: $tv-tmpl-nav-height;
    // vertically center text
    line-height: $tv-tmpl-nav-height;

    &.tv-nav-top {
      position: fixed;
      top: 0;
      left: 0;
    }

    &.tv-nav-bottom {
      position: fixed;
      bottom: 0;
      left: 0;
    }
  }

  // primary content
  .tv-content {
    margin: $tv-tmpl-nav-height $tv-tmpl-side-gutter 0 $tv-tmpl-side-gutter;
  }

  // alert messages
  .tv-alert {
    position: fixed;
    z-index: 9;
    top: $tv-tmpl-nav-height;
    left: $tv-tmpl-side-gutter;
  }

  // connection indicator
  .tv-alexa-session-indicator {
    position: fixed;
    z-index: 9;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
  }

  .if-connected {
    display: none;
  }

  .if-not-connected {
    display: none;
  }

  &.connected {
    .if-connected {
      display: block;
    }

    .hide-if-connected {
      display: none;
    }

    .tv-alexa-session-indicator {
      background-color: $ml-green-shade;
    }
  }

  &.not-connected {
    .if-not-connected {
      display: block;
    }

    .hide-if-not-connected {
      display: none;
    }

    .tv-alexa-session-indicator {
      background-color: $ml-orange-dark;
    }
  }


}

