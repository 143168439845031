@use '../../styles/app.scss' as *;

add-tag {
  display: inline-block;
  vertical-align: top;
  margin: 4px 0 4px 0;
  // do not set width/max-width of container, or the form/button group will act strangely
  // set width of .base-header-search-box instead
  // width: 500px;

  // dropdown ul is appended to this element
  .search-box {
    @media (max-width: ($ml-screen-tiny - 1)) {
      max-width: 100px;
    }
    @media (min-width: $ml-screen-tiny) and (max-width: ($ml-screen-sm - 1)) {
      max-width: 180px;
    }
    @media (min-width: $ml-screen-sm) and (max-width: ($ml-screen-md - 1)) {
      max-width: 400px;
    }
    @media (min-width: $ml-screen-md) {
      max-width: 500px;
    }

    font-size: 10px;
    flex-wrap: nowrap;

    input {
      padding: 2px 6px;
      font-size: 12px;
      height: 28px;
      width: 100%;
    }

    // the magnifying glass button
    .input-group-btn {
      width: 34px;

      @media (max-width: ($ml-screen-tiny - 1)) {
        display: none;
      }

      > .btn-sm {
        font-size: 12px;
        height: 28px;
        width: 34px;
      }
    }
  }
}
