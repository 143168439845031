.movie-row {
  position: relative;

  .movie-row-bounds {
    overflow-x: hidden;

    .movie-row-scrollable {
      white-space: nowrap;
      overflow-x: scroll;
      transition: all 0.2s cubic-bezier(.4, 0, .2, 1);
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .movie-row-item {
      white-space: normal;
      margin: 0 4px 0 0;
      display: inline-block;
      vertical-align: baseline;
    }

  }

  .movie-row-back-button {
    position: absolute;
    top: 40%;
    left: -6px;
    z-index: 10;
  }

  .movie-row-forward-button {
    position: absolute;
    top: 40%;
    right: -6px;
    z-index: 10;
  }

}
