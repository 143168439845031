@use '../../styles/app.scss' as *;

.base-star-dropdown {
  width: 500px;
  @media (max-width: $ml-screen-xs) {
    max-width: 80%;
  }

  .spaced-header {
    margin-top: 24px;
  }

  .notification {
    color: $ml-orange-dark;
  }

  .radio {
    margin-left: 6px;
  }

  .base-star-dropdown-line-item {
    margin-bottom: 12px;
  }

  .recommender-desc {
    text-transform: none;
    padding-left: 6px;
  }

  .recommender-desc-disabled > div {
    color: $ml-gray-light;
  }

}
