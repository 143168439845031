@use '../../styles/app.scss' as *;

.add-a-title {

  .add-title-by-imdb {
    margin-bottom: 20px;
  }

  .imdb-form {
    margin-bottom: 20px;

    .form-control-feedback {
      top: 0px;
    }
  }

  .can-i-stream-it-container {
    padding: 5px;
    background-color: $ml-off-white;
  }

  dl {
    dd {
      margin-bottom: 10px;
    }
  }

  .please-confirm {
    margin: 20px 0;
  }

}
