@use '../../styles/app.scss' as *;

.alexa-about-movie {
  vertical-align: top;

  .left-container {
    vertical-align: top;
    width: 40%;
    display: inline-block;
    padding: 40px 0 0 100px;

    .big-poster {
      width: 550px;
    }
  }

  .right-container {
    vertical-align: top;
    width: 50%;
    display: inline-block;

    .movie-title {
      padding-top: 10px;
    }

    .about-movie-section {
      margin-top: 20px;
    }

    .play-trailer-container {
      margin-top: 20px;
      position: relative;
      width: 300px;

      .play-trailer-button {
        position: absolute;
        top: 40px;
        left: 125px;
        font-size: $ml-font-size-huge;
      }
    }
  }


}
