@use '../../styles/app.scss' as *;

.dev-styles {

  .colors-container {
    padding: 10px;
    background-color: #fff;
  }
  .colors {
    margin-bottom: 10px;
  }

  .color-box {
    padding: 6px;
    display: inline-block;
    width: 100px;
    height: 100px;
  }
  .blue-light {
    color: $ml-font-color-soft-black;
    background-color: $ml-blue-light;
  }
  .blue {
    color: $ml-font-color-white;
    background-color: $ml-blue;
  }
  .blue-dark {
    color: $ml-font-color-white;
    background-color: $ml-blue-dark;
  }
  .orange {
    color: $ml-font-color-white;
    background-color: $ml-orange;
  }
  .orange-dark {
    color: $ml-font-color-white;
    background-color: $ml-orange-dark;
  }

  .off-white {
    color: $ml-font-color-soft-black;
    background-color: $ml-off-white;
  }
  .gray-lighter {
    color: $ml-font-color-soft-black;
    background-color: $ml-gray-lighter;
  }
  .gray-light {
    color: $ml-font-color-white;
    background-color: $ml-gray-light;
  }
  .gray {
    color: $ml-font-color-white;
    background-color: $ml-gray;
  }
  .gray-dark {
    color: $ml-font-color-soft-white;
    background-color: $ml-gray-dark;
  }
}
