@use '../../styles/app.scss' as *;

.movie-tags {
  .not-spaced {
    margin-left: 0 !important;
  }

  .control-group {
    display: inline-block;
    margin-left: 12px;
    font-size: $ml-font-size-small;

    div {
      display: inline-block;
    }
    button {
      font-size: $ml-font-size-h6;
      padding: 0 6px;
      margin-left: 6px;
    }
  }

}

@media (max-width: $ml-screen-sm) {
  .movie-tags {
    padding-left: 12px;
  }
}


.tag-busy-indicator-cog {
  color: $ml-color-med-gray;
  display: inline-block;
  margin-top: 0.5em;
}
