@use '../../styles/app.scss' as *;

.admin-expt-page {
  .exptProperty {
    h4 {
      display:inline-block;
      margin: 5px 5px 5px 0;
    }

    h4::after {
      content: ":"
    }

    .multiline {
      white-space: pre-wrap;
    }
  }
  .actions {
    float: right;
    padding: 1em;
    margin: 3em 2em 0 0;
    border: 1px solid black;

    button {
      display: block;
      margin: 0.25em;
    }
  }
}
