@use '../../styles/app.scss' as *;;

// mute these compared with the values computed in ml4-tag.js
$ml4-tag-positive: rgba(0, 255, 0, 0.3);
$ml4-tag-neutral: $white;
$ml4-tag-negative: rgba(255, 0, 0, 0.3);

$ml4-tag-width: 200px;

.tag {
  display: inline-block;
  width: $ml4-tag-width;
  margin-bottom: 8px;

  a {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }

  .btn-group {
    background-color: var(--bs-body-bg);
  }

  .ml4-tag-color-btn {
    width: 35px;
    padding: inherit 0;
    opacity: 1;
    border-color: var(--bs-border-color) !important;

    .tag-app-count {
      font-size: $ml-font-size-tiny;
    }
  }

  .ml4-tag-main-btn {
    max-width: ($ml4-tag-width - 70px);
    opacity: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ml4-tag-box {
    padding: 2px 4px;
    border: 1px solid var(--bs-secondary-border-subtle);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);

  }

  button.ml4-tag-ctrl-btn {
    padding: inherit 5px;
  }

  button.ml4-tag-color-btn.positive {
    background-color: $ml4-tag-positive;
  }

  button.ml4-tag-color-btn.neutral {
    background-color: $ml4-tag-neutral;
  }

  button.ml4-tag-color-btn.negative {
    background-color: $ml4-tag-negative;
  }

  .hidden-tag {
    text-decoration: line-through;
  }

  // override default styling
  .dropdown-options {
    @include no-glow;
    font-size: 14px;
    // largely derived from bootstrap's dropdowns.less
    padding: 5px 0px;
    margin: 0px;
    list-style: none;

    li.menu-header {
      margin: 6px 0 4px 3px;
    }

    hr {
      width: 100%;
      height: 1px;
      background: #fff;
      margin-top: 5px;
      margin-bottom: 5px;
      padding: 0px;
    }
  }
  .dropdown-options > li > a {
    display: block;
    padding: 4px 10px;
    color: #333333;
    clear: both;
  }
  .dropdown-options > li > a:hover,
  .dropdown-options > li > a:focus {
    cursor: pointer;
    text-decoration: none;
    background-color: rgb(230, 230, 230);
  }
}
