@use '../../styles/app.scss' as *;

.alexa-recommend {

  .tile {
    padding: 30px;
    margin: 0 5px;
    width: 16%;
    display: inline-block;

    .poster-container {
      position: relative;

       .movie-poster {

      }

      .star-prediction {
        position: absolute;
        bottom: 0;
        padding: 4px 10px;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%
      }
    }

    .tile-label {
      margin-top: 4px;
      overflow: hidden;
      height: 56px;
    }

  }

  .alexa-centered {
    text-align: center;
    padding:400px;
  }

}