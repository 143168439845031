@use '../../styles/app.scss' as *;;

header.base-compact-header .base-header-search-container {
  display: inline-block;
  vertical-align: top;
  margin: 4px 0 0 0;
  // do not set width/max-width of container, or the form/button group will act strangely
  // set width of .base-header-search-box instead
  // width: 500px;

  // dropdown ul is appended to this element
  .base-header-search-box {
    border: 1px solid var(--bs-border-color);
    border-radius: 1px;
    @media (max-width: ($ml-screen-tiny - 1)) {
      width: 100px;
    }
    @media (min-width: $ml-screen-tiny) and (max-width: ($ml-screen-sm - 1)) {
      width: 180px;
    }
    @media (min-width: $ml-screen-sm) and (max-width: ($ml-screen-md - 1)) {
      width: 400px;
    }
    @media (min-width: $ml-screen-md) {
      width: 500px;
    }

    font-size: 10px;
    //.tt-menu {
    //}

    display: flex;
    flex-wrap: nowrap;

    .tt-header {
      font-size: $ml-font-size-h6;
      color: $ml-gray-light;
      text-transform: lowercase;
      margin-bottom: 6px;
      margin-left: 6px;

      &.spaced {
        margin-top: 12px;
      }
    }

    // input with dropdown directive
    input.form-control {
      padding: 2px 6px;
      font-size: 12px;
      height: 28px;
      width: 100%;
    }

    // the magnifying glass button
    .input-group-btn {
      width: 34px;
      border-color: inherit;
      @media (max-width: ($ml-screen-tiny - 1)) {
        display: none;
      }

      > .btn-sm {
        font-size: 12px;
        height: 28px;
        width: 34px;
        border-color: inherit;
        border-radius: inherit;
      }
    }
  }
}
