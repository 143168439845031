@use '../../styles/app.scss' as *;

.ml4-movie-table {
  font-size: 14px;

  // see tables.scss
  // only effective if the contained "row" has a min-width
  .ml4-movie-table-responsive-container {
    width: 100%;
    overflow-y: visible;
    overflow-x: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    // need bottom padding, or the ratings menu gets cut off
    padding-bottom: 50px;
  }

  .ml4-movie-table-row {
    min-width: 600px;
    border-bottom: 1px solid $ml-gray-light;
    padding: 8px 4px 4px 4px;
    display: inline-block;
    width: 100%;

    cursor: pointer;
    &:hover {
      background-color: var(--bs-secondary-bg);
    }
  }

  .ml4-movie-table-col-first {
    float: left;
  }

  .ml4-movie-table-col {
    overflow: hidden;
    float: left;
  }

  .ml4-movie-table-col-last {
    float: right;
    overflow: hidden;
    display: inline-block;
  }

  .emphasis-col {
    margin-top:5px;
  }

  .metadata-col {
    font-size:12px;
    margin-top:8px;
  }

  .hidden-movie-field {
    text-decoration: line-through;
    opacity: 0.5;
  }

  .ml4-movie-table-gutter { width: 10px; }
  .ml4-movie-table-stars { width: 180px; height: 32px; margin-top: 2px;}
  .ml4-movie-table-title {width: 300px; font-size: 15px; }
  .ml4-movie-table-year { width: 50px; }
  .ml4-movie-table-mpaa { width: 40px; }
  .ml4-movie-table-runtime { width: 40px; text-align: right; }
  .ml4-movie-table-num-ratings { width: 70px; text-align: right; }
  .ml4-movie-table-genres {}
  .ml4-movie-table-wishlist { font-size: 16px; }

  .ml4-movie-table-extra {
    margin-left: 180px;

    .ml4-movie-table-extra-row {
      margin-top: 8px;
    }
  }

  // xs layout
  @media (max-width: ($ml-screen-sm - 1)) {
    .ml4-movie-table-gutter { /*@include responsive-invisibility(); */}
    .ml4-movie-table-stars {}
    .ml4-movie-table-title {}
    .ml4-movie-table-year {}
    .ml4-movie-table-mpaa {}
    .ml4-movie-table-runtime { /*@include responsive-invisibility();*/ }
    .ml4-movie-table-num-ratings { /*@include responsive-invisibility();*/ }
    .ml4-movie-table-genres { /*@include responsive-invisibility();*/ }
    .ml4-movie-table-wishlist {}
  }

  @media (min-width: $ml-screen-sm) and (max-width: ($ml-screen-md - 1)) {
    .ml4-movie-table-gutter {}
    .ml4-movie-table-stars {}
    .ml4-movie-table-title {}
    .ml4-movie-table-year {}
    .ml4-movie-table-mpaa {}
    .ml4-movie-table-runtime {}
    .ml4-movie-table-num-ratings {}
    .ml4-movie-table-genres { /*@include responsive-invisibility();*/ }
    .ml4-movie-table-wishlist {}
  }

}

.hyperlink{
  color: var(--bs-link-color-rgb);
  &:focus, &:hover {
    color: rgb(0, 0, 0);
  }
}
