@use '../../styles/app.scss' as *;

.info-page {

  .info-container {
    margin: 0 auto 20px auto;
    max-width: 770px;

    background-color: $white;
    padding: 20px 5% 40px 5%;
    border: 1px solid $ml-gray;
  }

  .info-footer {
    margin: 0 auto 20px auto;
    max-width: 770px;

    margin-top: 40px;
    padding-top: 10px;
    border-top: 1px solid $ml-color-light-gray;

  }

}
