@use '../../styles/app.scss' as *;

.frontpage {

  margin-bottom: 48px;

  .frontpage-section-top {
    margin-top: 24px;
  }

  .frontpage-section-header {
    margin-bottom: 6px;
  }

  label {
    font-size: $ml-font-size-h6;
    margin-left: 12px;
    color: $ml-color-med-gray;
  }

}
