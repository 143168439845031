@use '../../styles/app.scss' as *;

.explore {

  margin-top: 24px;

  .explore-page-desc {
    margin-bottom: 24px;

    p {
      margin: 0;
      color: $ml-color-med-gray;
      font-size: $ml-font-size-small;
    }

    h1 {
      margin: 0;
    }
  }

  .movie-search-results-container {
    overflow: visible;
    padding: 24px 0 0 0;
  }

}
