@use '../../styles/app.scss' as *;

.api-activity-indicator-cog {
  color: $ml-color-med-gray;

  @media (max-width: $ml-screen-md) {
    position: fixed;
    top: 0;
    right: 0;
  }
}
