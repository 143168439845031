@use '../../styles/app.scss' as *;

header.base-compact-header {
  background-color: var(--bs-secondary-bg);
  //border-bottom: 1px solid $ml-gray-lighter;
  margin: 0;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  .ratings-alert {
    color: $ml-orange-dark;
  }

  .base-compact-header-btn {
    @include no-glow;
    background-color: transparent;
    &:hover {
      border: 1px solid $ml-gray-light;
    }
    &:active {
      background-color: $ml-gray-lighter !important;
    }
    @media (max-width: ($ml-screen-md - 1)) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .base-header-switch {
    display: inline-block;
    padding-left: 10%;
    padding-top: 5px;
  }

  .base-header-primary-container {
    padding-top: 7px;
    padding-bottom: 8px;
  }

  .base-header-logo-container {
    float: left;
    margin-right: 50px;

    @media (max-width: ($ml-screen-md - 1)) {
      margin-right: 8px;
    }

    .base-header-logo-main {
      display: inline-block;
      vertical-align: top;
      margin-top: 3px;
      filter: drop-shadow(0px 0px 10px var(--bs-secondary-bg-subtle));
      @media (max-width: ($ml-screen-sm - 1)) {
        display: none !important;
      }
    }
  }

  .base-header-more-actions-container {
    float: right;
  }

  // a common style for several dropdowns available on the top nav bar
  .base-dropdown {
    border-top: none;
    padding: 6px;
    margin-top: 1px;

    font-size: $ml-font-size-h6;
    text-transform: uppercase;

    &.base-dropdown-vertical-shim {
      margin-top: 1px !important;
    }

    .menu-header {
      color: var(--bs-secondary-text-emphasis);
      font-weight: $ml-font-weight-bold;
      margin-top: 8px;
      margin-bottom: 6px;
    }

    p {
      text-transform: none;
    }

    ul {
      // largely derived from bootstrap's dropdowns.less
      padding: 0;
      margin-bottom: 0;
      list-style: none;

      li.menu-header {
        margin: 6px 0 4px 3px;
      }

      li > a {
        display: block;
        padding: 3px 3px;
        clear: both;
        color: var(--bs-light-text-emphasis);
        white-space: nowrap;
      }

      li > a {
        &:hover,
        &:focus {
          text-decoration: none;
          color: $ml-font-color-white;
          background-color: $ml-orange-dark;
        }
      }
    }
  }

}

.base-compact-header-spacer {
  // should be the same as the height of the menu
  height: 48px;
}

footer.base-compact-footer {
  color: $ml-font-color-white;
  background-color: $ml-gray-dark;
  font-size: $ml-font-size-smaller;
  padding-bottom: 48px;

  a {
    color: $ml-font-color-white;
    text-decoration: underline;
  }
;

  a:hover {
    color: var(--bs-dark-bg-subtle);
  }

  .ml4-footer-row {
    margin-top: 36px;
    margin-bottom: 24px;
  }

  .gl-logo {
    margin-bottom: 8px;
  }

  .section-header {
    padding: 12px 0 6px 0;
    @media (max-width: $ml-screen-md) {
      padding: 24px 0 12px 0;
    }
  }

  ul > li {
    margin-bottom: 8px;
  }

  li.header {
    font-size: $ml-font-size-h4;
  }
}

// llm_rec expt sophia sun00587 start
.survey-button {
  background: $ml-orange !important;
  margin-left: 1em;
}
// llm_rec expt sophia sun00587 end