@use '../../styles/app.scss' as *;

$movie-card-sm1-width: 140px;
$movie-card-sm1-height: 100px;
$movie-card-sm1-padding: 4px;

$movie-card-sm1-poster-width: 60px;
$movie-card-sm1-poster-spacing: 4px;

.movie-card-sm1 {
  display: inline-block;
  width: $movie-card-sm1-width;
  height: $movie-card-sm1-height;
  border: 1px solid transparent;
  @include border-radius($ml-border-radius);
  padding: $movie-card-sm1-padding;
  overflow: hidden;

  &:hover {
    border-color: $ml-gray-light;
    background: var(--bs-border-color);
  }

  font-size: 12px;
  cursor: pointer;

  .poster-container {
    display: inline-block;
    vertical-align: top;
    width: $movie-card-sm1-poster-width;
    height: $movie-card-sm1-height - (2 * $movie-card-sm1-padding);
    margin-right: $movie-card-sm1-poster-spacing;
    overflow-y: hidden;
    @include border-radius($ml-border-radius);

    .small-movie-poster {
      width: 100%;
      overflow-y: hidden;
      display: inline;
    }

    &.empty-poster {
      background: $ml-gray-dark;
    }
  }

  .metadata-container {
    display: inline-block;
    width: $movie-card-sm1-width - $movie-card-sm1-poster-width - $movie-card-sm1-poster-spacing - (4 * $movie-card-sm1-padding);
    vertical-align: top;
    overflow: hidden;

    .card-title {
      font-size: 12px;
    }

  }

}
