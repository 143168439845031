/* ===========================================
   1) GLOBAL / COMMON STYLES
   =========================================== */
.user-profile-page {
  font-family: Raleway, sans-serif;
  color: #555;
  background: #fff;
  padding: 20px;

  .profile-nav {
    border-bottom: 1px solid #ddd;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;

      li {
        padding: 10px 20px;
        cursor: pointer;
        font-weight: bold;
        color: #555;

        &.active {
          background: #f7f2ef;
          border-bottom: 3px solid #f06624;
          color: #f06624;
        }

        &:not(.active):hover {
          background: #f9f9f9;
        }
      }
    }
  }

  .row {
    display: flex;
    margin: 20px;
  }
}

/* A generic container for page sections (profile, treemap, timeline). */
.page-container {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0;

  .column {
    padding: 0 25px;
    margin-bottom: 30px;

    @media (min-width: 1200px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    @media (max-width: 1200px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .col-70 {
    @media (min-width: 768px) {
      flex: 0 0 70%;
      max-width: 70%;
    }
  }

  .col-30 {
    @media (min-width: 768px) {
      flex: 0 0 30%;
      max-width: 30%;
    }
  }
}

/* A reusable card style for all pages. */
.profile-card,
.treemap-card {
  background: #fafafa;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;

  h4 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;

    .highlight {
      color: #f06624;
      font-weight: bold;
    }
  }

  ul {
    padding-left: 20px;
  }

  p {
    margin: 0;
  }
}

.note {
  margin: 0 auto;
  font-size: 15px;
}

.update-button, .save-button {
  background: #f06624;
  color: #fff;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 10px;
}


/* ===========================================
   2) USER PROFILE SPECIFIC
   =========================================== */
.user-profile-container {
  .profile-overview-container {
    @extend .page-container; /* uses the global .page-container styles */

    .profile-column {
      @extend .column;
    }

    .user-taste-overview {
      background: #fff; /* or #fafafa if you prefer a slight difference */
      padding: 0 20px;

      h3 {
        margin-top: 0;
        color: #f06624;
        font-size: 26px;
        margin-bottom: 20px;
        font-weight: 700;
      }

      p, pre {
        font-family: inherit;
        font-size: 15px;
        line-height: 1.8;
        margin-bottom: 20px;
      }

      pre {
        border-bottom: 1px solid #ddd;
      }

      textarea {
        border: 1px solid #ddd;
        border-radius: 4px;
        width: 100%;
        height: 120px;
        padding: 10px;
        background: #fafafa;
        color: #555;
      }

      h4 {
        font-size: 18px;
        margin-top: 30px;
        margin-bottom: 15px;
        color: #555;
        font-weight: 600;
      }

      ul {
        margin: 0 0 20px 20px;
        padding: 0;
        font-size: 15px;

        li {
          margin-bottom: 10px;
        }
      }

      .edit-prompt {
        font-size: 14px;
        margin-top: 30px;
      }

      .cancel-button {
        background: dimgray;
        color: #fff;
        border: none;
        padding: 6px 12px;
        cursor: pointer;
        border-radius: 3px;
        font-size: 14px;
        margin-right: 10px;
      }
    }

    .user-word-cloud {
      text-align: center;
      width: 90%;
      margin: 0 auto;

      .word-cloud-title {
        font-size: 50px;
        color: #f06624;
        margin-bottom: 25px;
        font-weight: bold;
      }

      .word-cloud-background {
        background: #fcecec;
        border: 1px solid #f2cccc;
        padding: 30px;
        border-radius: 4px;
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;

        .word-cloud-subtext {
          font-size: 15px;
          color: #777;
          line-height: 1.6;
          text-align: center;
        }
      }
    }
  }
}

/* ===========================================
   3) TREEMAP SPECIFIC
   =========================================== */
.treemap-section {
  @extend .page-container; /* uses the global .page-container styles */

  .treemap-column {
    @extend .column;
  }

  /* Instead of repeating .treemap-card styles,
     let’s reuse .profile-card or keep .treemap-card if you want the same style but different name. */
  .treemap-placeholder {
    text-align: center;

    img {
      max-width: 100%;
      border: 2px solid #ccc;
      border-radius: 8px;
    }
  }

  .treemap-feedback {
    text-align: center;
    margin-top: 15px;

    .feedback-button {
      background: #f06624;
      border: none;
      color: #fff;
      padding: 10px 20px;
      margin: 5px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background: #d85a1f;
      }
    }
  }
}

/* Additional container for information columns in the Treemap page. */
.treemap-info {
  .profile-card { /* same structure as global .profile-card */
    background: #fafafa;
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;

    h2 {
      font-size: 32px;
      margin-top: 0;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      color: #555;
      margin: 0;
    }
  }
}

/* ===========================================
   4) TIMELINE SPECIFIC
   =========================================== */
.interactive-user-profile-timeline {
  /* Inherits the same global font/color from .user-profile-page,
     but if you have special overrides, define them here */
  padding: 20px;
  margin: 20px;

  .timeline-container {
    @extend .profile-card;
    /* Just override what's different */
    padding: 30px 0;
  }

  .timeline-summary-section {
    margin-top: 30px;

    .timeline-details {
      @extend .profile-card;
      padding: 15px;

      ul {
        margin: 0 0 20px 20px;
        padding: 0;
        font-size: 15px;

        li {
          margin-bottom: 10px;
          color: #555;
        }
      }
    }
  }
}
