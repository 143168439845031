@use '../../../styles/app.scss' as *;

$expt-movie-card-new-width: 154px;
$expt-movie-card-new-height: 280px;
$expt-movie-card-new-poster-height: 231px;
@import '~ngx-bar-rating/themes/br-horizontal-theme.css';
@import '~ngx-bar-rating/themes/br-square-theme.css';

.myDpSelectorAbsolute {
  position: relative;
}

.br {
  display: initial;
  position: relative;
  margin: 0 0;
}

.green {
  margin: 10px;
  color: green;
  font-size: $ml-font-size-large;
}

.red {
  margin: 10px;
  color: red;
  font-size: $ml-font-size-xsmall;
}


.expt-movie-card-md1 {
  // XXX: use float; inline block creates unintentional margin-right of about 4 pixels (see https://css-tricks.com/fighting-the-space-between-inline-block-elements/)
  //display: inline-block;
  float: left;
  // margin mirrors shadow below
  margin: 0 1px 10px 0;
  position: relative;
  height: $expt-movie-card-new-height;
  width: $expt-movie-card-new-width;
  overflow-x: visible;
  font-size: $ml-font-size-xsmall;
  //padding-bottom: 20px;

  movie-card-actions-menu {
    position: absolute;
    top: 8px;
    right: 6px;
    z-index: 2;

    .actions-button {
      visibility: hidden;
    }
  }

  .input-box{
    width: 150px;


  }

  &:hover movie-card-actions-menu {
    .actions-button {
      visibility: visible;
    }
  }

  .checkmark {
    content: 'L';
    font-family: arial;
    -ms-transform: scaleX(-1) rotate(-35deg); /* IE 9 */
    -webkit-transform: scaleX(-1) rotate(-35deg); /* Chrome, Safari, Opera */
    transform: scaleX(-1) rotate(-35deg);
    display: inline-block;
    vertical-align: top;
    line-height: 1em;
    width: 1em;
    color: green;
    height: 1em;
    margin-right: 0.3em;
    text-align: center;
    position: absolute;
    right: 0;
  }

  .card {
    background: $white;
    @include border-radius($ml-border-radius);
    display: inline-block;
    height: $expt-movie-card-new-height;
    width: $expt-movie-card-new-width;
    position: relative;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    overflow-y: hidden;
    overflow-x: hidden;

    // card hover effects
    &:hover {
      box-shadow: 0 2px 18px rgba(0, 0, 0, 0.5);
    }

    .poster:hover {
      cursor: pointer;
    }

    .overlay .title:hover {
      color: $ml-blue !important;
      cursor: pointer;
    }

    .title2 {
      position: relative;
      text-align: left;
      line-height: 19px;
      //margin: 8px 0px 0 8px;
      margin-top: 45px;
      font-size: $ml-font-size-small;
      color: rgba(0, 0, 0, 0.8);
      top: 0;
      z-index: 3; //2;//3
    }

    .poster {
      width: $expt-movie-card-new-width;
      height: $expt-movie-card-new-poster-height;
      position: relative;
      top: 0;
      z-index: 1;

      img {
        max-width: 100%;
        @include border-radius($ml-border-radius);
      }

      &.poster-hidden {
        img {
          opacity: 0.1;
        }
      }

      &.empty-poster {
        background: $ml-gray-dark;
      }
    }

    .my-overlay {
      position: absolute;
      bottom: 0;
      width: $expt-movie-card-new-width;
      background: $white;
      z-index: 3; //2;//3
      max-height: $expt-movie-card-new-height;

      // overlay hover effects
      &:hover {
        .my-above-fold {
          .year {
            display: inherit;
          }

          .rating-indicator {
            display: none;
          }

          max-height: $expt-movie-card-new-height;
        }

        .my-below-fold {
          max-height: $expt-movie-card-new-height;
        }
      }


      // .above-fold is always shown
      .my-above-fold {

        margin-bottom: 8px;
        max-height: $expt-movie-card-new-height;

        .title {
          position: relative;
          text-align: left;
          line-height: 19px;
          margin: 8px 8px 0 8px;
          font-size: $ml-font-size-xsmall;
          color: rgba(0, 0, 0, 0.8);
          top: 0;
          z-index: 1;
        }

        .rating-indicator {
          position: relative;
          margin: 3px 0 0 7px;
        }

        .wishlist {
          float: right;
          color: $ml-orange-dark;
          margin-right: 7px;
          margin-top: 2px;
          font-size: $ml-font-size-xsmall;
        }

        .year {
          position: relative;
          text-align: left;
          margin: 6px 0 0 9px;
          height: 14px;
          color: rgba(0, 0, 0, 0.6);
          display: none;
        }

      }

      // .below-fold is shown on hover
      // XXX: chrome and firefox interpret vertical margin differently ... test changes carefully across browsers!
      .my-below-fold {
        max-height: 0;
        transition: max-height 0s; //0.2s;
        margin: 0 8px;

        .genre-list {
          text-align: left;
          margin: 0 8px;
          padding-top: 9px;

          .genre {
            text-align: center;
            margin: 0 5px 5px 0;
            @include border-radius($ml-border-radius);
            padding: 0 4px;
            background: $ml-blue;
            display: inline-block;

            &:hover {
              cursor: pointer;
            }

            a {
              color: $white;
              font-weight: 300;
              line-height: 12px;
              letter-spacing: 0.1px;
            }
          }
        }

        .ratings-display {
          text-align: center;
          padding-top: 6px;
          color: rgba(0, 0, 0, 0.6);

          .rating-predicted {
            display: inline-block;
          }

          .rating-average {
            display: inline-block;
            margin-left: 7px;
          }

          .rating-large {
            text-align: left;
            margin: 0 0 0 0;
            font-size: $ml-font-size-xsmall;
            line-height: 12px;
          }

          .rating-total {
            text-align: left;
            font-size: $ml-font-size-tiny;
            line-height: 10px;
          }

          .rating-caption {
            text-align: center;
            margin: 0 0 0 0;
            font-size: $ml-font-size-smaller;
            line-height: 16px;
          }
        }

        .rating-entry {
          padding: 8px 0 0 2px;
        }

      }

      // end .below-fold

    }

    // end .overlay

    .md-button {
      padding: 0 6px 0 6px;
      margin: 6px 8px 6px 8px;
      min-width: 88px;
      border-radius: 0px;
      font-size: 10px;
      text-align: left;
      text-transform: uppercase;
      text-decoration: none;
      border: none;
      outline: none;
    }

    .app-button {
      background-color: #F0FFFF;
      width: 47%;
      margin: 0;
      padding: 0;
      font-size: 12px;
      //display:inline-block;
      line-height: 20px;
      position: relative;
      top: 0;
      z-index: 2; //4
      text-align: center;
    }

    .row {
        position: relative;
        margin-top: 10px;
        margin-bottom: 5px;
        /* margin-left: 0px; */
        text-align: center;
        marin-right: 0px;
        /* align-content: center; */
        /* align-items: stretch; */
    }


  }

  // end .card


  //.groupX {
  //  font-size: 16px;
  //  margin: 20px 0;
  //  padding: 3px 15px 3px 15px;
  //  color: rgb(49, 46, 46);
  //  background-color: rgba(224, 224, 224, 0.96);
  //  text-transform: none;
  //  font-weight: 400;
  //  min-width:100px;
  //}

  //.md-button.left {
  //  border-radius: 10px 0 0 10px;
  //}
  //
  //.md-button.middle {
  //  border-radius: 0;
  //  border-left: 1px solid rgba(230, 230, 230, 0.96);
  //  border-right: 1px solid rgba(230, 230, 230, 0.96);
  //}
  //
  //.md-button.right {
  //  border-radius: 0 10px 10px 0;
  //}
  //
  //.md-button:not([disabled]):hover {
  //  background-color: rgba(193, 193, 193, 0.96);
  //  color: rgba(44, 65, 164, 0.96);
  //  transition: 0.3s;
  //}

}

.myDpYearValue{
  font-size: 12px
}






