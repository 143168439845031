@use "../../styles/app.scss" as *;

.marketing-container {
  // max-width aligned with 6-wide movie posters in explore page
  max-width: 800px + $ml-gutter-width;
  // this padding compensates for bootstrap's `margin: 0 -15px;` used on .row.
  padding: 0 calc($ml-gutter-width / 2);
  margin-right: auto;
  margin-left: auto;
  //@include clearfix(); //not sure how to fix
}


.marketing-page {
  font-size: $ml-font-size-base;
  .big-header {
    background-color: $ml-orange;
    padding: 18px 0;
    margin-bottom: 24px;
  }


  .header-img {
    margin: 50px 0 30px 0;
  }

  h3.page-description {
    //@include marketing-container();
    margin-bottom: 24px;
  }

  .spaced {
    margin-top: 48px;
  }
  .half-spaced {
    margin-top: 24px;
  }

  form {

    label {
      font-weight: $ml-font-weight-medium;
      width: 100%;
    }

    input, select {
      // placeholder($ml-font-color-softer-black); 
      max-width: 500px;
    }
  }

  #alert-box {
    position: fixed;
    top: 71px;
    left: 0;
    width: 100%;

    @media (min-width: 830px) {
      top: 71px + 6px;
    }

    .alert {
      padding: 12px;
    }
  }

}