@use '../../styles/app.scss' as *;

.restricted-compact-header {

  .restricted-header-username {
    font-size: $ml-font-size-small;
    color: var(--bs-secondary-color);
  }

  .main-menu {
    margin: 7px 0 8px 0;
  }

  #alert-box {
    .alert {
      top: 12px;
    }
  }
}
