@use '../../styles/app.scss' as *;

.explanation-template {
  margin: 0 auto 20px auto;
  max-width: 1500px;

  background-color: $white;
  padding: 0 5% 20px 5%;
  border: 1px solid $ml-gray;
}

.natural-language {
  display: inline;
}

.survey-star-shape {
  fill: #bbb;
  stroke: #808080;
}

.survey-star-shape.predicted {
  fill: #3bace1;
}

.survey-star-shape.rated {
  fill: #f06624;
}