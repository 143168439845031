@use '../../styles/app.scss' as *;

// see https://www.w3schools.com/css/css_tooltip.asp
tooltip {

  /*
   * hover effect -- wrap in media query to avoid touble-tap issue on IOS
   * see https://css-tricks.com/annoying-mobile-double-tap-link-issue/
   */
  @media (hover) {
    &:hover .tooltip-text {
      visibility: visible;
      opacity: 1;
      // Use a transition (and opacity above) to "fade in" over a total of 700 ms, 500ms before popping up the tooltip
      // at all, and another 200ms fade in.
      transition: visibility 200ms linear 500ms, opacity 200ms linear 500ms;
    }
  }

  .tooltip-component {
    position: relative;
    display: inline-block;
  }

  .tooltip-text {
    // common
    visibility: hidden;
    opacity: 0;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: $ml-border-radius;
    padding: 5px 0;
    position: absolute;
    z-index: 999;

    // "bottom" orientation (default)
    top: 150%;
    left: 50%;
    margin-left: -60px;
  }

  // arrow hack
  .tooltip-text::after {
    // common
    content: "";
    position: absolute;

    // "bottom" orientation
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }

  // "right" orientation
  &.tooltip-right {

    .tooltip-text {
      margin-left: auto;

      top: 0;
      left: 110%;
    }

    .tooltip-text::after {
      bottom: auto;
      left: auto;
      margin-left: auto;

      top: 0;
      right: 100%;
      margin-top: 5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent black transparent transparent;
    }

  }

  // done to make sure that tool tip used by rating is a little higher than the default value.
  &.tooltip-rating{
    .tooltip-text {
      top: 100%
    }
  }

  &.tooltip-top {

    .tooltip-text {
      top: -120%;
      left: 50%;
      box-sizing: border-box;
      border-radius: 3px;
    }

    .tooltip-text::after {
      top: 100%;
      left: 50%;
      transform: rotate(180deg);
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent black transparent;
    }

  }

}

