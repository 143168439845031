@use '../../styles/app.scss' as *;

.explore-control-tools {

  .clear-button {
    margin-left: 10px;
  }

  .search-tools-box {
    margin-top: 30px;
    position: relative;
    button.close {
      font-size: $ml-font-size-h3;
      position: absolute;
      right: 14px;
      top: 7px;
      z-index: 100;
      border-color: transparent;
      background-color: transparent;

      color: $ml-font-color-soft-black;
      &:hover {
        color: $ml-font-color-black;
      }
    }

    .narrow-input {
      max-width: 200px;
    }
  }

}
