@use '../../styles/app.scss' as *;;

.admin-movie-page {
  .movieProperty {
    h4 {
      display:inline-block;
      margin: 5px 5px 5px 0;
    }

    h4::after {
      content: ":"
    }
  }

  .version {
    color: #bbb;
    font-size: 80%;
    display: inline-block;
    padding: 0.5em;
    border: 1px solid black;
    margin: 0.25em;
  }

  .actions {
    float: right;
    padding: 1em;
    margin: 3em 2em 0 0;
    border: 1px solid black;

    button {
      display: block;
      margin: 0.25em;
    }
  }

  .edit-field {
    margin: 2px 2px 2px 2px;
    display: flex;
    align-items: center;

    span {
      display:inline-block;
      width:10em;
    }
    input {
      width:40.8em;
    }
  }
}
