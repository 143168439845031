@use '../../styles/app.scss' as *;

#container {
  width: 80%;
  /*border-radius: 25px;*/
  border: 2px solid Black;
  padding: 15px 15px 15px 15px;
  margin: 20px 20px 20px 20px;
  background: #A4D3EE;
  overflow: visible;
  box-shadow: 5px 5px 2px #888888;
  position: relative;
}

#x {
  position: absolute;
  background: white;
  width: 30px;
  height: 30px;
  color: white;
  top: 0px;
  right: 0px;
}

#x2 {
  position: absolute;
  background: white;
  width: 30px;
  height: 30px;
  color: white;
  top: 0px;
  right: 0px;
}

//.explanation-template {
//  margin: 0 auto 20px auto;
//  max-width: 1500px;
//
//  background-color: @white;
//  padding: 0 5% 20px 5%;
//  border: 1px solid @ml-gray;
//}
//
//.natural-language {
//  display: inline;
//}
//
//.survey-star-shape {
//  fill: #bbb;
//  stroke: #808080;
//}
//
//.survey-star-shape.predicted {
//  fill: #3bace1;
//}
//
//.survey-star-shape.rated {
//  fill: #f06624;
//}
.rec-val-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
}

.rec-val-overlay-2 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
  z-index: 10;
}

.rec-val-overlay-3 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
  z-index: 10;
}

.topcorner {
  position: absolute;
  top: 20px;
  right: 50px;
}

.popup {
  margin: 30px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 50%;
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 5;
  overflow-y: auto;
  font-size: 14px;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
  z-index: 5;
}

.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popup .close:hover {
  color: #06D85F;
  cursor: pointer;
}

.popup .content {
  max-height: 30%;
  overflow: auto;
  z-index: 5;
}

.popup::-webkit-scrollbar {
  height: 12px;
}

.popup::-webkit-scrollbar-track {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15) inset;
  background: #f0f0f0;
}

.popup::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #ccc;
}

@media screen and (max-width: 700px) {

  .popup {
    width: 70%;
    z-index: 5;
  }
}

.animate-show-hide.ng-hide {
  opacity: 0;
}

.animate-show-hide.ng-hide-add,
.animate-show-hide.ng-hide-remove {
  transition: all linear 0.5s;
}

.check-element {
  border: 1px solid black;
  opacity: 1;
  padding: 10px;
}

.row-centered {
  text-align: center;
}

.col-centered {
  display: inline-block;
  float: none;
  /* reset the text-align */
  text-align: left;
  /* inline-block space fix */
  margin-right: -4px;
}

.col-md-2 {
  display: inline-block;
}

.app-button2 {
  background-color: #F0FFFF;
  width: 100px;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 4px;
  marin-right: 0px;
  font-size: 24px;
  line-height: 40px;
  //position: center;
  top: 0;
  z-index: 10;
  text-align: center;
  horiz-align: right;
  display: table-cell;
}


.app-button3 {
  background-color: #F0FFFF;
  width: 150px;
  //margin:0 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 0px;
  marin-right: 0px;
  //font-size: 24px;
  //display:inline-block;
  line-height: 40px;
  //position: center;
  top: 0;
  z-index: 10;
  text-align: center;
  horiz-align: center;
  display: table-cell;
}

.row2 {
  display: table;
  //position:absolute;
  //top:0;
  //bottom:10px;
  //left:0;
  //right:10px;
  //margin:auto;
  //position: center;
  top: 0;
  //z-index: 1;
  margin: 0 auto;
  horiz-align: right;
  /*the same margin which is every button have, it is for small screen, and if you have many buttons.*/
  //margin-top: 10px;
  //margin-bottom: 10px;
  //margin-left: 10px;
  //marin-right:10px;
}

.row3 {
  display: table;
  //position:absolute;
  //top:0;
  //bottom:10px;
  //left:0;
  //right:10px;
  //margin:auto;
  //position: center;
  //top: 10px;
  //margin-top: 10;
  //z-index: 1;
  margin: 10px auto;
  horiz-align: right;
  /*the same margin which is every button have, it is for small screen, and if you have many buttons.*/
  //margin-top: 10px;
  //margin-bottom: 10px;
  //margin-left: 10px;
  //marin-right:10px;
}