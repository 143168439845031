@use '../../styles/app.scss' as *;

.pick-groups {

  .intro {
    margin: 24px 0;
  }

  .controls {
    margin: 24px 0;
    min-height: 36px;

    .points-left {
      color: $ml-orange;
    }
  }

  .cluster {
    margin: 0 24px 24px 0;
    @media (min-width: $ml-screen-lg) {
      margin: 0 48px 24px 0;
    }

    display: inline-block;

    .cluster-points {
      font-size: 12px;
      display: inline-block;
      vertical-align: middle;

      @media (max-width: $ml-screen-xs) {
        padding-bottom: 4px;
        div {
          display: inline-block;
        }
      }

      .score {
        font-size: $ml-font-size-h4;
        text-align: center;
        color: $ml-orange;
      }
    }

    .cluster-box {
      display: inline-block;
      vertical-align: middle;
      background-color: var(--bs-secondary-bg);
      padding: 6px;
      margin-left: 4px;
      border: 1px solid $ml-gray-light;
      @include border-radius($ml-border-radius);

      .cluster-tags {
        margin-bottom: 12px;
        color: var(--bs-body-color);
      }
    }
  }

}
