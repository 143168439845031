@use '../../styles/app.scss' as *;;

.tag-page {
  margin-top: 24px;
  margin-bottom: 24px;

  .feedback {
    margin-top: 24px;
    margin-bottom: 24px;
    p {
      margin: 0;
      color: $ml-color-med-gray;
    }
  }

  .tag-header {
    margin-bottom: 24px;
  }

  .tag-data-heading {
    @include movie-details-header-font;
  }

  .tag-data-sub-heading {
    font-size: $ml-font-size-h4;
  }

  .tag-stats {
    margin-top: 24px;
  }

  .movie-search-results-container {
    overflow: visible;
    padding: 24px 0 0 0;
  }

  .panel-unsure {
    border-color: $ml-color-light-gray;
  }

}
