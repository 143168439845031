@use '../../styles/app.scss' as *;

.movie-tuner {

  .tuner-subhead {
    font-size: $ml-font-size-h6;
    font-weight: $ml-font-weight-medium;
    color: var(--bs-info-text-emphasis);
    display: inline-block;
  }

  .invalid-tag {
    display: inline-block;
    padding: 0px;
    text-align: left;
    color: red;
  }

  .movie-search-results-container {
    margin-top: 10px;
  }

  button {
    margin-left: 10px;
  }

  .table {
    border-collapse: separate;
  }
  .tuner-controls {
    width:500px; 

    .space {
      text-align: center;
      width:200px;
    }
    .tag {
      text-align:left;
      margin-bottom: 0;
    }
    .tuner-radio {
      text-align: center;
    }
  }
}