@use '../../styles/app.scss' as *;

.profile-tmpl {

  .profile-top {
    margin-top: 24px;
  }

  .left-nav {
    margin-top: 24px;
    border-right: 1px solid $ml-gray-lighter;
  }

  .main-profile-content {
    h1 {
      margin-bottom: 36px;
    }
  }

  @media (min-width: $ml-screen-sm) {
    .main-profile-content {
      padding-left: 24px;
    }
  }

  input {
    max-width: 500px;
  }

  label {
    width: 100%;
  }

}
