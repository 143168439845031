@use '../../styles/app.scss' as *;

$movie-card-sm2-width: 240px;
$movie-card-sm2-height: 100px;
$movie-card-sm2-padding: 4px;

$movie-card-sm2-poster-width: 60px;
$movie-card-sm2-poster-spacing: 4px;

.movie-card-sm2 {
  display: inline-block;
  width: $movie-card-sm2-width;
  height: $movie-card-sm2-height;
  border: 1px solid transparent;
  @include border-radius($ml-border-radius);
  padding: $movie-card-sm2-padding;
  //overflow: hidden;

  &:hover {
    border-color: $ml-gray-light;
    background: $white;
  }

  font-size: 12px;
  cursor: pointer;

  .poster-container {
    display: inline-block;
    vertical-align: top;
    width: $movie-card-sm2-poster-width;
    height: $movie-card-sm2-height - (2 * $movie-card-sm2-padding);
    margin-right: $movie-card-sm2-poster-spacing;
    overflow-y: hidden;
    @include border-radius($ml-border-radius);

    .small-movie-poster {
      width: 100%;
      overflow-y: hidden;
      display: inline;
    }

    &.empty-poster {
      background: $ml-gray-dark;
    }
  }

  .metadata-container {
    display: inline-block;
    width: $movie-card-sm2-width - $movie-card-sm2-poster-width - $movie-card-sm2-poster-spacing - (4 * $movie-card-sm2-padding);
    vertical-align: top;

    white-space: nowrap;
    overflow-x: hidden;

    .card-title {
      font-size: 16px;
      margin-bottom: 4px;
    }

    ul {
      margin: 0;
      padding: 0;
      font-size: 12px;
    }
    li {
      display: inline;
      margin-right: 4px;
    }

  }

}
