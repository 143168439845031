@import "../../../styles/app.scss";

$explanations-movie-card-width: 176px;
$explanations-movie-card-height: 320px;
$explanations-movie-card-poster-height: 200px;

.explanations-movie-card {
  // XXX: use float; inline block creates unintentional margin-right of about 4 pixels (see https://css-tricks.com/fighting-the-space-between-inline-block-elements/)
  //display: inline-block;
  float: left;
  // margin mirrors shadow below
  margin: 0 1px 3px 0;
  position: relative;
  height: $explanations-movie-card-height;
  width: $explanations-movie-card-width;
  overflow-x: visible;
  text-align: center;
  font-size: $ml-font-size-small;

  .survey-card {
    background: $white;
    @include border-radius($ml-border-radius);
    height: $explanations-movie-card-height;
    width: $explanations-movie-card-width;
    position: relative;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    overflow-y: hidden;

    // card hover effects
    &:hover {
      box-shadow: 0 2px 18px rgba(0, 0, 0, 0.5);
    }

    .selection-button {
      position: absolute;
      top: 8px;
      right: 6px;
      z-index: 2;

      .fa-check-circle {
        font-size: 48px;
        text-shadow: 0 1px 1px rgba(100, 0, 0, 0.5);

        &.selected {
          color: $ml-green;
        }

        &.not_selected {
          color: gray;
        }
      }

      &:hover {
        cursor: pointer;
        .fa-check-circle {
          text-shadow: 0 1px 10px rgba(1000, 0, 0, 0.8);
        }
      }
    }
  }

  .survey-poster {
    height: $explanations-movie-card-poster-height;
    width: $explanations-movie-card-width;
    position: relative;
    top: 0;
    z-index: 1;

    img {
      max-width: 100%;
      @include border-radius($ml-border-radius);
      height: $explanations-movie-card-poster-height;
      width: $explanations-movie-card-width;
    }

    &.poster-hidden {
      img {
        opacity: 0.1;
      }
    }
  }

  .survey-title {
    position: relative;
    text-align: left;
    line-height: 19px;
    margin: 8px 8px 0 8px;
    font-size: 20px;
    color: rgba(0, 0, 10, 0.8);
  }

  .survey-year {
    position: absolute;
    top: 300px;
  }

  .survey-genre-list {
    text-align: left;
    margin: 0 8px;
    padding-top: 8px;

    .survey-genre {
      text-align: center;
      margin: 0 5px 5px 0;
      @include border-radius($ml-border-radius);
      padding: 0 4px;
      background: $white;
      display: inline-block;
      color: rgba(0, 0, 0, 0.6);
      border: 1px solid rgba(0, 0, 0, 0.6);
    }

    .survey-genre-small {
      text-align: center;
      margin: 0 5px 5px 0;
      @include border-radius($ml-border-radius);
      padding: 0 4px;
      background: $white;
      display: inline-block;
      color: rgba(0, 0, 0, 0.6);
      border: 1px solid rgba(0, 0, 0, 0.6);
      font-size: 10px;
    }
  }

  .text-style {
    text-align: left;
    height: 14px;
    color: rgba(0, 0, 0, 0.6);
    opacity: 1;
    margin: 0 0 0 3px;
  }

  .survey-star-shape {
    fill: #bbb;
    stroke: #808080;
  }
  .survey-star-shape.predicted {
    fill: #3bace1;
  }
  .survey-star-shape.rated {
    fill: #f06624;
  }
}
