@use '../../styles/app.scss' as *;
$movie-card-new-width: 154px;
$movie-card-new-height: 280px;
$movie-card-new-poster-height: 231px;

.movie-card-md1 {
  // XXX: use float; inline block creates unintentional margin-right of about 4 pixels (see https://css-tricks.com/fighting-the-space-between-inline-block-elements/)
  //display: inline-block;
  float: left;
  // margin mirrors shadow below
  margin: 0 0 3px 0;
  position: relative;
  height: $movie-card-new-height;
  width: $movie-card-new-width;
  overflow-x: visible;
  font-size: $ml-font-size-small;

  movie-card-actions-menu {
    position: absolute;
    top: 8px;
    right: 6px;
    z-index: 2;
    .actions-button {
      visibility: hidden;
    }
    &:hover {
      z-index: 4;
    }
  }
  &:hover movie-card-actions-menu {
    .actions-button {
      visibility: visible;
    }
  }

  .card {
    background: var(--bs-body-bg);
    @include border-radius($ml-border-radius);
    display: inline-block;
    height: $movie-card-new-height;
    width: $movie-card-new-width;
    position: relative;
    box-shadow: 0 1px 3px rgba(0,0,0,0.5);
    overflow-y: hidden;

    // card hover effects
    &:hover {
      box-shadow: 0 2px 18px rgba(0,0,0,0.5);
    }

    .poster:hover {
      cursor: pointer;
    }

    .overlay .title:hover {
      color: $ml-blue !important;
      cursor: pointer;
    }

    .poster {
      width: $movie-card-new-width;
      height: $movie-card-new-poster-height;
      position: relative;
      top: 0;
      z-index: 1;

      img {
        max-width: 100%;
        @include border-radius($ml-border-radius);
      }

      &.poster-hidden {
        img {
          opacity: 0.1;
        }
      }

      &.empty-poster {
        background: $ml-gray-dark;
      }
    }

    .overlay {
      position: absolute;
      bottom: 0;
      width: $movie-card-new-width;
      background: var(--bs-tertiary-bg);
      z-index: 3;

      // overlay hover effects
      &:hover {
        .above-fold {
          .year, .dateRated {
            display: inherit;
          }
          .rating-indicator {
            display: none;
          }
        }

        .below-fold {
          max-height: $movie-card-new-height;
          text-align: center;
        }
      }

      // .above-fold is always shown
      .above-fold {

        margin-bottom: 8px;

        .title {
          position: relative;
          text-align: left;
          line-height: 19px;
          margin: 8px 8px 0 8px;
          font-size: $ml-font-size-base;
          color: var(--bs-light-text-emphasis);
        }

        .rating-indicator {
          position: relative;
          margin: 3px 0 0 7px;
        }

        .wishlist {
          float: right;
          color: $ml-orange-dark;
          margin-right: 7px;
          margin-top: 2px;
          font-size: $ml-font-size-smaller;
        }

        .year, .dateRated {
          position: relative;
          text-align: left;
          margin: 6px 0 0 9px;
          height: 14px;
          color: var(--bs-info-text-emphasis);
          display: none;
        }

      }

      // .below-fold is shown on hover
      // XXX: chrome and firefox interpret vertical margin differently ... test changes carefully across browsers!
      .below-fold {
        max-height: 0;
        transition: max-height 0.2s;

        .genre-list{
          text-align: left;
          margin: 0 8px;
          padding-top: 9px;

          .genre {
            text-align: center;
            margin: 0 5px 5px 0;
            @include border-radius($ml-border-radius);
            padding: 0 4px;
            background: $ml-blue;
            display: inline-block;

            &:hover {
              cursor: pointer;
            }

            a {
              color: $white;
              font-weight: 300;
              line-height: 12px;
              letter-spacing: 0.1px;
            }
          }
        }

        .ratings-display {
          text-align: center;
          padding-top: 6px;
          color: var(--bs-info-text-emphasis);

          .rating-predicted {
            display: inline-block;
          }

          .rating-average {
            display: inline-block;
            margin-left: 7px;
          }

          .rating-large {
            text-align: left;
            margin: 0 0 0 0;
            font-size: $ml-font-size-base;
            line-height: 12px;
          }

          .rating-total {
            text-align: left;
            font-size: $ml-font-size-tiny;
            line-height: 10px;
          }

          .rating-caption {
            text-align: center;
            margin: 0 0 0 0;
            font-size: $ml-font-size-smaller;
            line-height: 16px;
          }
        }

        .rating-entry {
          padding: 8px 0 0 0;
        }

      } // end .below-fold

    } // end .overlay

  } // end .card

}



