@use "../../styles/app.scss" as *;

// see https://www.w3schools.com/css/css_tooltip.asp
dropdown {

  .dropdown-component {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    background-color: var(--bs-tertiary-bg);
    text-align: left;
    border-radius: $ml-border-radius;
    border-style: solid;
    border-width: thin;
    border-color: var(--bs-border-color);
    position: absolute;
    z-index: 99;

    margin-top: 0px;
    padding-top: 0px;
    top: 100%;
    max-width: 100vw;
  }
}
