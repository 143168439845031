@use '../../styles/app.scss' as *;

//This content is pulled almost directly, and without modifications, from bootstrap's dropdown code -- the only
//modification is to not require the li items to be a direct child of the dropdown-menu element so we can have angular host
//items get in the way.


.dropdown-menu >li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.5;
    white-space: nowrap; // prevent links from randomly breaking onto new lines

    &:hover,
    &:focus {
      color: shade-color(#212529, 10%);
      text-decoration: none;
      background-color: #e9ecef;
    
  }
}

.dropdown-menu-right {
  right: 0;
  display: block;
  left: auto;
}

// custom styles for nested-dropdown component
li.dropdown-submenu-root {
  position: relative;

  .dropdown-submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
    padding: 6px 10px;
  }

  .dropdown-submenu-left {
    right: 100%;
    left: auto;
  }

  &:hover > .dropdown-submenu {
    display: block;
  }
}

