@use '../../styles/app.scss' as *;;

.rating-or-prediction {
  display: inline-block;

  &.none {
    display: none;
  }

  &.rated svg > polygon {
    fill: $ml-orange;
  }

  &.predicted svg > polygon {
    fill: $ml-blue;
  }

  &.average svg > polygon {
    fill: $ml-gray;
  }

  .star-icon {
    top: 2px;
    left: 0;
  }

  .rating-value {
    display: inline;
    position: relative;
    text-align: left;
    padding-left: 3px;

    line-height: 18px;
    margin: 0 0 0 0;
    font-size: $ml-font-size-small;
    color: $ml-gray;
  }
}
