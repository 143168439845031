@use '../../styles/app.scss' as *;

.movie-alt {

  // override default styling
  .dropdown-options {
    @include no-glow;
    font-size: 14px;
    // largely derived from bootstrap's dropdowns.less
    padding: 0px;
    margin-bottom: 0;
    list-style: none;

    li.menu-header {
      margin: 6px 0 4px 3px;
    }

  }
  .dropdown-options  > li > a {
    display: block;
    padding: 4px 10px;
    color: #333333;
    clear: both;
  }
  .dropdown-options  > li > a:hover, .dropdown-options > li > a:focus {
    cursor: pointer;
    text-decoration: none;
    background-color: rgb(230,230,230);
  }

  .movie-details-header {
    background-color: var(--bs-secondary-bg); //secondary
    border: 1px solid var(--bs-border-color);
    padding: 48px 0;
    margin-bottom: 48px;

    .header-backdrop {
      overflow: hidden;
      position: relative;
      @media (min-width: $ml-screen-md) {
        img {
          @include rounded;
        }
      }

      .movie-trailer-play {
        font-size: 8em;
        color: black;
        z-index: 11;
        opacity: 0.5;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);

        &:hover {
          opacity: 1;
        }
        &::before {
          width: 1em;
          height: 1em;
          display: inline-block;
          text-align: center;
          // TODO: we should probably try a few other alpha's here as well as a few other standard colors (and if
          //  necissary non-standard colors) until we find the right match. we can also change the color of the icon..
          background: fade($ml-color-light-gray, 50%);
          border-radius: 50%;
        }
      }
    }


    @media (min-width: $ml-container-max-width) {
      margin-top: 48px;
    }

    @media (max-width: $ml-screen-lg) {
      border-left: none;
      border-right: none;
    }
    @media (max-width: $ml-screen-md) {
      padding-top: 0;
      .header-backdrop {
        padding: 0;
      }
    }
    @media (max-width: $ml-screen-md) and (orientation: landscape) {
      .header-backdrop {
        max-height: 50vh;
      }
    }

    img.full-width {
      position:relative;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      &.fade.in {
        opacity: 0.9;
      }
      &:hover + .movie-trailer-play {
        opacity: 1;
      }
    }

    .movie-title {
      padding: 0;
      margin-bottom: 0;
      @media (min-width: $ml-screen-md) {
        margin: -6px 0 0 0;
      }
    }

    .original-movie-title {
      color: $ml-color-med-gray;
    }

    .movie-widgets {
      margin: 6px 0 0 0;
      .rating-box {
        vertical-align: top;
      }
      .movie-widget-box {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .movie-highlights {
      margin-top: 12px;
    }
  }

  //@include plot-summary { }

  .movie-details-heading {
    @include movie-details-header-font;
  }

  .movie-details-sub-heading {
    font-size: $ml-font-size-h4;
  }

  .movie-details-block {
    margin-bottom: 48px !important;
  }

  .movie-details-media-block {
    margin-bottom: 24px;
  }

  .heading-and-data {
    margin: 12px 0 0 0;
  }

  ul.movie-attr {
    font-size: $ml-font-size-small;
    margin: 12px 0 0 0;
    padding: 0;

    @media (max-width: $ml-screen-md) {
      margin-top: 24px;
    }

    li {
      display: inline;
      margin-right: 4px;
    }
  }
}
